import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { LoanStatus } from "pages/dashboard/home/dashboardService";
import { baseQueryWithAuth } from "services/common/rtkQueryConfig";

export interface AuthActionResponse {
  id?: string; //NhDealApplicationId
  userId: string;
  applicationRoleType: string;
  nhApplicationId?: string;
  dealApplicationId?: string;
  prospectId?: string;
  version: string;
  hasLosLoanNumber: boolean;
  loanStatus?: LoanStatus;
  applicationStatus: string;
  loanPurpose: string;
  hasPAL?: boolean;
  loanId?: string;
  leadProvider?:string;
  registrationRoleType?:string;
}
export interface NotificationResponse {
  id: string;
  userId: string;
  title: string;
  text: string;
  link: string;
  createdOn: Date;
}
export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQueryWithAuth, //baseQuery
  endpoints: (build) => ({
    getAuthActions: build.query<AuthActionResponse, string>({
      query: (userId: string) => ({
        url: `/dealapplication/user/${userId}?losloannumber=true&loanstatus=true`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
    getNotificationDetails: build.query<NotificationResponse[], string>({
      query: (userId: string) => ({
        url: `/notification/user/${userId}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
    setStatusFlagOfNotification: build.mutation<void,  string >({
      query: (id:string) => ({
        url: `/notification/${id}/isViewed-flag`,
        method: "PATCH",
      }),
    }),
  }),
});

export const { useGetAuthActionsQuery } = userApi;
export const { useGetNotificationDetailsQuery } = userApi;
export const { useSetStatusFlagOfNotificationMutation } = userApi;
